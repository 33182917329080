<template>
    <div class="secondary-table" :class="adaptiveClass">
        <div v-if="!hiddenHead" class="secondary-table__header">
            <h3 class="typography typography--h3">{{ headerTitle }}</h3>
            <SelectComponent
                :searchable="true"
                @input="searchBySelect($event)"
                v-if="selectInfo"
                :value="selectInfo.defaultValue"
                :options="selectInfo.options"
                :name="selectInfo.name"
                class="secondary-table__select"
                :clearable="clearable"
            />
        </div>
        <table class="table">
            <thead>
            <tr class="table__labelTr">
                <th
                    v-for="column in headerColumns"
                    :key="column.id"
                    @click="cellClick(column)"
                    :style="'width:'+ (100 / headerColumns.length) + '%'"
                    :class="{
                            sorted: column.sort,
                        }"
                >
                    <p class="typography typography--head table__label" :class="{ 'table__label--pointer': column.sort }">
                        {{ column.label }}
                        <img src="@/assets/img/sort.svg" v-if="column.sort" alt="svg" />
                    </p>
                </th>
            </tr>
            </thead>

            <tbody v-if="!preloader">
            <tr v-if="!item.haveParent" v-for="item in sortedItems" :key="item.id">
                <td v-for="k in headerColumns" :key="k.id">
                    <div :class="lineBrakeClass(cellContent(k, item))">
                        {{ cellContent(k, item) }}
                    </div>
                </td>
            </tr>

            <tr
                v-if="!item.group && item.show || item.group"
                @click="toggleItem(item)"
                :class="{
                        child: !item.group,
                        parent: item.group,
                        opened: item.group && item.is_open
                    }"
                v-for="item in sortedItems"
                :key="item.id"
            >
                <td v-for="k in headerColumns" :key="k.id">
                    <div :class="lineBrakeClass(cellContent(k, item))">
                        <button type="button" class="toggle-btn" v-if="item.group"></button>
                        {{ cellContent(k, item) }}
                        <span class="counter" v-if="item.group">[{{item.cinemasCount}}]</span>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <div class="empty" v-if="!items.length && !preloader">
            <Empty />
        </div>
        <lazy-component v-if="lazyLoad" @show="lazyLoadHandler"></lazy-component>
        <Preloader v-if="preloader"></Preloader>
        <Pagination v-if="meta.total" class="pagination" :value="pageNumber" :total="meta.total" :per-page="meta.per_page" @input="changePage"></Pagination>
    </div>
</template>

<script>
import Pagination from "@/components/ui/tables/Pagination"
import SelectComponent from "@/components/ui/forms/SelectComponent"
import SearchComponent from "@/components/ui/forms/SearchComponent"
import DatePickerComponent from "@/components/ui/forms/DatePickerComponent"
import Empty from "../../Empty";
import Preloader from "../Preloader";

export default {
    name: "TableMain",
    components: { Pagination, SearchComponent, SelectComponent, DatePickerComponent, Empty, Preloader },
    props: {
        headerColumns: {
            required: false,
            type: Array,

            default() {
                return [
                    {
                        id: 1,
                        label: this.$t('Name'),
                        attribute: "name",
                        sort: true,
                    },
                ]
            },
        },
        headerTitle: {
            required: false,
            type: String,
            default: '',
        },
        clearable: {
            required: false,
            type: Boolean,
            default: false
        },
        lazyLoad: {
            required: false,
            type: Boolean,
            default: false
        },
        hiddenHead: {
            required: false,
            type: Boolean,
            default: false
        },
        preloader: {
            required: false,
            type: Boolean,
            default: false
        },
        items: {
            required: true,
            type: Array,
        },
        type: {
            required: false,
            type: String,
            default: "raw",
        },
        itemsMeta: {
            required: false
        },
        selectInfo: {
            required: false,
            default: null
        },
        scroll: {
            required: false,
            type: String,
            default: null, // md, lg
        }
    },
    data() {
        return {
            sort: {
                key: "",
                isAsc: false,
            },
            removeModal: false,
            removeId: 0,
            startDate: Date.now(),
            haveChildren: false,
            childrenList: [],
            listHaveChildren: {
                parent: {

                },
                children: {

                }
            }
        }
    },
    computed: {
        adaptiveClass() {
            if (this.scroll) {
                return 'adaptive--' + this.scroll
            }
            return false
        },
        pageNumber() {
            return this.itemsMeta.current_page
        },
        meta() {
            return this.itemsMeta;
        },
        sortedItems() {
            const list = this.items.slice()

            if (!!this.sort.key) {
                list.sort((a, b) => {
                    if (typeof a[this.sort.key] == "number") {
                        let name1 = a[this.sort.key]
                        let name2 = b[this.sort.key]
                        if (this.sort.isAsc) {
                            return name1 > name2 ? 1 : name1 < name2 ? -1 : 0
                        } else {
                            return name1 < name2 ? 1 : name1 > name2 ? -1 : 0
                        }
                    } else {
                        return a[this.sort.key].localeCompare(b[this.sort.key]) * (this.sort.isAsc ? 1 : -1)
                    }
                })
            }
            return list
        },
        sortedChildren() {
            const list = this.childrenList.slice()
            if (!!this.sort.key) {
                list.sort((a, b) => {
                    if (typeof a[this.sort.key] == "number") {
                        let name1 = a[this.sort.key]
                        let name2 = b[this.sort.key]
                        if (this.sort.isAsc) {
                            return name1 > name2 ? 1 : name1 < name2 ? -1 : 0
                        } else {
                            return name1 < name2 ? 1 : name1 > name2 ? -1 : 0
                        }
                    } else {
                        return a[this.sort.key].localeCompare(b[this.sort.key]) * (this.sort.isAsc ? 1 : -1)
                    }
                })
            }
            this.listHaveChildren.children = list
            return list
        },
    },
    methods: {
        cellClick(column) {
            if (column?.urlAttribute) {
                return column.sort ? this.sortBy(column.urlAttribute) : null
            }
            return column.sort ? this.sortBy(column.attribute) : null
        },
        lazyLoadHandler(){
            this.$emit('lazyLoadHandler')
        },
        lineBrakeClass(text) {
            if (!text || typeof text == "number") return ""
            let textTrim = text?.replace(/ +/g, " ").trim()
            let str = textTrim?.split(" ").length - 1
            if (str <= 2) {
                return "word-wrap"
            }
            return ""
        },
        searchByColumn(value, searchBy) {
            //this.$emit()
        },
        searchBySelect(v) {
            this.$emit('getSelectValue', v)
        },
        getData(v) {
            let value = this.helper.getDate(v)
            this.searchByColumn(value, 'date')
        },
        changePage(p) {
            this.$emit("getPage", p)
            if (this.isSearchPanel) {
                this.$emit("changePage", p)
            }
            this.$router.push({ query: Object.assign({}, this.$route.query, {page: p }) }).catch(()=>{});
            this.$emit('getItems', this.$route.query)
        },
        toggleItem(item){
            if(!item.group) {
                return false;
            }

            this.$emit('toggle', item.id);
        },
        sortBy(key) {
            if (this.$route.query?.sort_dir == 'asc') {
                this.sort.isAsc = false
            }
            else {
                this.sort.isAsc = true
            }
            this.sort.key = key;

            this.$router.push({ query: Object.assign({}, this.$route.query, { sort_by: key, page: 1 }) }).catch(()=>{})

            if (this.sort.isAsc) {
                this.$router.push({ query: Object.assign({}, this.$route.query, { sort_dir: 'asc' }) }).catch(()=>{})
            }
            else {
                this.$router.push({ query: Object.assign({}, this.$route.query, { sort_dir: 'desc' }) }).catch(()=>{})
            }
            this.$emit('getItems', this.$route.query)
        },
        cellContent(key, item) {
            if (key.attribute) {
                if (key.attribute == "created_at" && this.isSearchPanel) {
                    let d = new Date(item[key.attribute] * 1000)
                    let dFormat = d
                        .toISOString()
                        .slice(0, 10)
                        .split("-")
                    let res = `${dFormat[2]}.${dFormat[1]}.${dFormat[0]}`
                    return res + " " + d.getHours() + ":" + d.getMinutes()
                }
                if (key.attribute == "json_data.title" && this.isSearchPanel) {
                    return item?.json_data?.title || "-//-"
                }
                if (key.attribute.includes('.')) {
                    let newKeys = key.attribute.split('.');
                    if (!item[newKeys[0]]) {
                        return "---"
                    }
                    return item[newKeys[0]][newKeys[1]]
                }
                if (key.attribute == "is_active") {
                    return !item[key.attribute] ? this.$t('No active') : this.$t('Is active')
                }
                return item[key.attribute]
            } else {
                return key.value(item)
            }
        },
    }
}
</script>

<style lang="scss">
.secondary-table {
    padding: 0 15px 15px;
    background-color: white;
    overflow-x: auto;

    &.adaptive--md {
        @media screen and (max-width: $md) {
            justify-content: flex-start;
            min-width: $md + 1px;
            overflow-x: auto;
        }
    }

    &.adaptive--lg {
        @media screen and (max-width: $lg) {
            justify-content: flex-start;
            min-width: $lg + 1px;
            overflow-x: auto;
        }
    }

    &__header {
        background-color: $color_lightpink;
        padding: 0 15px;
        height: 50px;
        display: flex;
        align-items: center;
        margin: 0 -15px;
        justify-content: space-between;

        h3{
            color: white;
        }

    }

    &__select {
        width: 238px;
        margin-left: 25px;

        @media screen and (max-width: $sm) {
            width: 150px;
        }

        .vs--searchable {
            background-color: #F4BED6;
        }
    }
    .child-item td:first-child{
        padding-left: 112px;
    }
    .child td:first-child{
        padding-left: 112px;
    }
    .parent{
        font-size: 16px;
        font-weight: 900;
        line-height: 1.69;
        color: #141213;
        cursor: pointer;
        .counter{
            color: $color_lightpink;
            display: none;
        }
        & td:first-child .counter{
            display: inline;
        }
        .toggle-btn{
            display: none;
        }
        & td:first-child .toggle-btn{
            display: inline;
            margin-right: 17px;
            background-color: $color_darkblue;
            border: none;
            border-radius: 50%;
            height: 20px;
            width: 20px;
            position: relative;
            top: 3px;
        }
        .toggle-btn:before{
            content: "+";
            color: white;
            position: relative;
            bottom: 5px;
            right: 1px;
            font-weight: bold;
        }
        @-moz-document url-prefix(){
            .toggle-btn:before{
                bottom: 5px;
                right: 0px;
            }
        }

    }
    .opened{
        td:first-child .toggle-btn{
            background-color: $color_lightpink;
            &:before{
                content: '—';
                bottom: 6px;
                right: 4px;
            }
            @-moz-document url-prefix(){
                &:before{
                    bottom: 6px;
                    right: 2px;
                }
            }
        }

    }

    .child.d-none{
        max-height: 0;
        transition: max-height 1s ease-out;
    }
    .child{
        max-height: 80px;
        transition: max-height 1s ease-in;
    }

}
</style>

<style lang="scss" scoped>
.table {
    .table__labelTr{
        border-color: $color_lightgray!important;
        border-bottom: 1px solid $color_lightgray!important;
    }
    tbody, td, tfoot, th, thead, tr{
        border-style: inset;
    }
    thead {
        th {
            padding: 20px 0;
            padding-right: 10px;
        }

    }

    &__labelTr {
        //border-style: solid !important;
        background-color: white;
    }

    &__label {
        display: flex;
        align-items: center;
        font-size: 12px;
        text-transform: uppercase;
        line-height: 1.5;
        letter-spacing: 2px;

        &--pointer {
            cursor: pointer;
        }
    }

    tbody {
        border-top: 1px solid $color_lightgray;

        tr {
            border-color: $color_light;
            td div{
                font-size: 16px;
                color: #646e78;
            }
        }

        .parent{
            .word-wrap{
                color: black;
            }
        }

        td {
            padding: 15px 0;
            padding-right: 10px;
            vertical-align: middle;
        }
    }
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
        transition: .1s;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 42px;

        &:hover {
            background-color: $color_light;
        }
    }

    button span {
        color: $color_blue;
    }
}

.pagination {
    margin-top: 30px;
}

.empty {
    text-align: center;
    margin-top: 50px;

    &__title {
        font-size: 16px;
    }
}

::v-deep{
    tr{
        border-bottom: 1px solid #EDF3FF;
        border-style: double;
        height: 65px;

    }
    .pagination .button{
        color: black;
    }
    .pagination .button--primary{
        border: 1px solid #cad9f37a;
    }
    .pagination .button:hover{
        color: black;
    }
    .pagination{
        display: flex;
        align-items: center;
    }
}
</style>
