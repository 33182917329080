<template>
<div class="statistics-distributor container">
    <PageHeader
        class="title"
        :title="$t('Home page')"
    />
    <div class="table">
        <TableSecondary
            :items="statistics"
            :headerTitle="$t('Movie sales')"
            :itemsMeta="meta"
            :header-columns="columns"
            scroll="md"
            :selectInfo="selectOptions"
            :preloader="preloader"
            @getSelectValue="getSelectValue"
            :clearable="true"
            @getPage="getPage"
        />
    </div>
</div>
</template>

<script>
import PageHeader from "../../components/PageHeader";
import TableSecondary from "../../components/ui/tables/TableSecondary";

export default {
    name: "StatisticsDistributors",
    components:{
        PageHeader,
        TableSecondary
    },
    data() {
        return {
            statistics: [],
            meta: [],
            dateFrom: '01-01-2021',
            dateTo: '24-10-2021',
            page: 1,
            format: 'month',
            columns: [
                {
                    id: 1,
                    label: this.$t('Movie'),
                    sort: false,
                    attribute: "name",
                },
                {
                    id: 2,
                    label: this.$t('Tickets'),
                    sort: false,
                    attribute: "stats.tickets_count",
                },
                {
                    id: 3,
                    label: this.$t('Sum'),
                    sort: false,
                    attribute: "stats.tickets_amount",
                },
            ],
            preloader: true,
            selectOptions: {
                defaultValue: 1,
                options: [
                    {id: 1, label: this.$t('Today')},
                    {id: 2, label: this.$t('Yesterday')},
                    {id: 3, label: this.$t('This week')},
                    {id: 4, label: this.$t('This month')},
                ]
            },
        }
    },
    methods:{
        async getDistributorsStatistic() {
            let resp = await this.api.statistics.getMoviesStatForDistributor(this.format, this.dateFrom, this.dateTo, this.page)
            this.statistics = resp.data
            this.meta = resp
            this.preloader = false
        },
        getSelectValue(v){
            this.selectOptions.defaultValue = v
            if (v === 1){
                this.dateFrom = this.helper.getDate(new Date())
                this.dateTo = this.helper.getDate(new Date())
            } else if (v === 2){
                this.dateFrom = this.helper.getYesterdayDate(new Date())
                this.dateTo = this.helper.getYesterdayDate(new Date())
            } else if (v === 3){
                this.dateFrom = this.helper.getCurrentWeek(new Date())
                this.dateTo = this.helper.getDate(new Date())
            } else if (v === 4){
                this.dateFrom = this.helper.getCurrentMonth(new Date())
                this.dateTo = this.helper.getDate(new Date())
            }
            this.getDistributorsStatistic()
        },
        getPage(page){
            this.page = page
            this.getDistributorsStatistic()
        },
    },
    created() {
        this.dateFrom = this.helper.getDate(new Date())
        this.dateTo = this.helper.getDate(new Date())
        this.getDistributorsStatistic()
    }
}
</script>

<style scoped lang="scss">
.statistics-distributor{
    .title{
        margin-top: 30px;
    }
    .table{
        @media (max-width: 992px) {
            overflow-x: scroll;
            white-space: nowrap;
        }
    }
}
</style>
